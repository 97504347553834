import {React, useState} from "react";
import validator from "email-validator";
import {Helmet} from "react-helmet";

function Arfusoft(){
  return (
    <div className="py-12 text-base-content text-base">
    <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
      <div className="mx-auto max-w-md">    
        <div className="mt-2 divide-y divide-gray-300/50">
          <div className="space-y-6 py-8 text-base leading-7 text-base-content text-base">
            <p className="text-base-content text-base">
              <a className="text-blue-500 hover:text-blue-600 font-bold" href="https://arfusoft.com/" target="_blank" rel="noreferrer">Arfusoft</a> is the software development company that designed and implemented this website.
              <span> Our company has years of experience in the development of:</span>
            </p>
            <ul className="space-y-4">
              <li className="flex items-center">
                <svg className="h-6 w-6 flex-none fill-none dark:fill-none stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="11" />
                  <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                </svg>
                <p className="ml-4 text-base-content text-base">
                  Web
                  <code className="text-sm font-bold text-base-content text-base"> frontend</code> applications
                </p>
              </li>
              <li className="flex items-center">
                <svg className="h-6 w-6 flex-none fill-none dark:fill-none stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="11" />
                  <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                </svg>
                <p className="ml-4 text-base-content text-base">
                  Web
                  <code className="text-sm font-bold text-base-content text-base"> backend/server</code> applications
                </p>
              </li>
                          <li className="flex items-center">
                <svg className="h-6 w-6 flex-none fill-none dark:fill-none stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="11" />
                  <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                </svg>
                <p className="ml-4 text-base-content text-base">
                  <code className="text-sm font-bold text-base-content text-base">Mobile</code> applications for Android and IOS
                </p>
              </li>
                                      <li className="flex items-center">
                <svg className="h-6 w-6 flex-none fill-none dark:fill-none stroke-blue-400 stroke-2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="11" />
                  <path d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9" fill="none" />
                </svg>
                <p className="ml-4 text-base-content text-base">
                  <code className="text-sm font-bold text-base-content text-base">Desktop</code> applications for Win and MAC
                </p>
              </li>
            </ul>
          </div>
        </div>
        </div>
        </div>
        </div>
  )
}

export function ContactForm({domain}){
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [outputText, setOutputText] = useState('')


  const [error, setError] = useState(null);
  const [customError, setCustomError] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(false);


  function validEmail(emailInput) {
    return validator.validate(emailInput);
  }


  const postMessage =() => { 
    setButtonClicked(true);
    setIsLoaded(false);
    setOutputText('');    
    setError('');
    setCustomError('');


    const data = {name: name, email:email, phone:'', amount:'', domain:domain, message:message};
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data )
    };
    const url = `https://marketing-bmphr.ondigitalocean.app/websites/contacts/`;

    if(name === ''){
      setIsLoaded(true);
      setCustomError("Please enter your name!");
    }
    else if( (email === '') || (validEmail(email) === false) ){
      setIsLoaded(true);
      setCustomError("Please enter a valid email!");
    }
    else if((message === '') ){
      setIsLoaded(true);
      setCustomError("Please enter a message!");
    }
    else
    {
        fetch(url, requestOptions)
        .then(res => res.text())
        .then(
        (result) => {
            setIsLoaded(true);
            setOutputText(result);

            setName('');
            setEmail('');
            setMessage('');
        },
        (error) => {
            setIsLoaded(true);
            setError(error);   
        });

    }      
          
}

    return (
        <>        
          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm text-base-content">
          <p className="text-base-content text-base">You can contact us by email at <a className="font-bold text-blue-500 hover:text-blue-600" href="mailto:contact@arfusoft.com">contact@arfusoft.com </a> 
          or fill the contact form below.</p>
              <div className="form-control relative w-full py-4">
                <input type="text" className="input input-lg max-w-full pl-10 input-ghost-primary" placeholder="Your name"  value={name} onChange={event => setName(event.target.value)} />
    
                <span className="absolute inset-y-0 left-3 inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/></svg>
                        </span>
                      </div>
    
                      <div className="form-control relative w-full">
                        <input type="email" className="input input-lg max-w-full pl-10 input-ghost-primary" placeholder="Your email"  value={email} onChange={event => setEmail(event.target.value)} />
    
                        <span className="absolute inset-y-0 left-3 inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
                        </span>
                      </div>
                      <div className="py-2">
                      <textarea className="textarea-block textarea textarea-ghost-primary w-full pt-2" placeholder="Your message to us" value={message} onChange={event => setMessage(event.target.value)} />
                      </div>
                      
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-rounded btn-block mb-1"
                            onClick={postMessage}
                          >
                            Submit your message
                          </button>
                        </div>
                        <div className="flex justify-center items-center">
                            { customError && <p className="py-1 text-sm text-red-400">{customError}</p> }
                        </div>
                        <div className="flex justify-center items-center  text-red-400">
                            { error && <p className="py-1 text-sm text-red-400">{error.message}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
                        </div>
                        <div className="flex justify-center items-center">
                             <p className="py-1 text-sm text-purple-400 font-semibold">{outputText}</p>
                        </div>
                    </div>
      </>
      );
}


export default function Contact() {
    return (
      <>
       <br /> <br />

       <Helmet>
          <meta charSet="utf-8" />
          <title>Contact us</title>
          <meta name="description" content="Get in touch with Arfusoft, the company that operates the Special Gift Ideas website." />              
          <meta name="keywords" content="contact us,contact us form"/>
          <meta property="og:title" content="Contact us" />        
          <meta property="og:description" content="Get in touch with Arfusoft, the company that operates the Special Gift Ideas website." />        
          <meta property="og:site_name" content="Contact us" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
        </Helmet>

        
      <section className="text-base-content bg-base-100 relative py-6">
      <h2 className="flex items-center justify-center text-xl text-base"><b> Contact Us</b></h2>
        <div className="container px-5 pt-4 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-1/2 md:w-1/2 rounded-lg overflow-hidden sm:mr-10">
          <Arfusoft/>
          </div>
          <div className="lg:w-1/2 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <ContactForm domain={"specialgiftideas.net"}/>
          </div>
        </div>
      </section>
      </>
  
    );
    }