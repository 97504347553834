import {React, useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import {Helmet} from "react-helmet";




function ArticleDisplay({title, date, monthyear, content}){

    useEffect(() => {
      
    }, [])
    return (
        <>
        <br /><br />
        <section className="body-font  text-base text-black-500 dark:text-white">
          <div className="container px-5 py-12 mx-auto">
            <h1 className="flex justify-center items-center px-auto font-semibold sm:text-3xl text-2xl text-sky-600">{title}</h1> 
            <p  className="flex justify-center items-center px-auto ">By Narcisse T.</p> 
            <p  className="flex justify-center items-center px-auto ">Last updated: {date} {monthyear}</p><br />  <hr /> <br />
              <div className="">
                  <div className="px-2 py-2">
                        <div dangerouslySetInnerHTML={{__html : content} }></div>
                  </div>

              </div>
          </div>
      </section> 

        </>

    );
}





    function ArticleView (){
        let { id } = useParams();

        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [items, setItems] = useState([]);

        useEffect(() => {
                const fileID = parseInt(id);
                const url = `https://goldfish-app-asx3v.ondigitalocean.app/gift/${fileID}`;
                
                fetch(url)
                  .then(res => res.json())
                  .then(
                    (result) => {
                      setIsLoaded(true);
                      setItems(result);
                    },
                    (error) => {
                      setIsLoaded(true);
                      setError(error);
                    }
                  );   
        }, [id])// <-- }, [])  empty array means 'run once'
    

        if (error) {
          return <div className="px-12 py-12 flex justify-center items-center dark:text-white">Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div className="px-12 px-12 py-12 flex justify-center items-center dark:text-white">Loading...</div>;
        } else {
                return(
                    <div  className="z-0">

                      <Helmet>
                        <meta charSet="utf-8" />
                        <title>{items.Title}</title>
                        <meta name="description" content={`${items.SmallDescription}`}/>              
                        <meta property="og:title" content={items.Title} />        
                        <meta property="og:description" content={`${items.SmallDescription}`} />        
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:type" content="article" />
                      </Helmet>




                        { 
                            <ArticleDisplay
                            key={items.Id} 
                            title={items.Title}
                            date={items.Date}
                            monthyear={items.MonthAndYear}
                            content={items.LongHtmlDescription}
                            ></ArticleDisplay>
                        }
                    </div>
            
                );
            }
    }
    
export  default ArticleView;