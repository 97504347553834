import React from "react";
import {Helmet} from "react-helmet";
import ArticleListing from "./Article";

function Christian(){
    return(
        <div>
            <br /><br />

            <Helmet>
                <meta charSet="utf-8" />
                <title>Our Gift ideas for Christian festivals and holidays.</title>
                <meta name="description" content="Learn about Christian festivals and the best gift ideas for these occasions." />              
                <meta name="keywords" content="gift ideas,christianism,christian festivals,christian holidays"/>
                <meta property="og:title" content="Our Gift ideas for Christian festivals and holidays." />        
                <meta property="og:description" content="Learn about Christian festivals and the best gift ideas for these occasions." />        
                <meta property="og:site_name" content="Christian festivals" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
            </Helmet>

               
            <section className="text-gray-600 body-font">
            <h1 className="flex justify-center items-center px-auto text-xl font-bold py-6 dark:text-sky-700">Christian festivals</h1>
            <div className="container px-5 py-1 mx-auto  dark:text-white">
                    <p> Christianity is a major world religion based on the life, teachings, death, and resurrection of Jesus Christ. Christians believe Jesus is the Son of God and the Savior of humanity.
                    </p>
                    <p>
                    The core Principles of Christianity are: <br />
                    &bull; <b>Faith in Jesus Christ</b>: Belief in Jesus as the Son of God and Savior. <br /> 

                    &bull; <b>The Holy Trinity</b>: The Father, the Son, and the Holy Spirit.<br />

                    &bull; <b>Love and Compassion</b>: Following Jesus' command to love God and neighbors.<br />

                    &bull; <b>Salvation and Grace</b>: Salvation through faith and God's grace.<br />

                    &bull; <b>The Bible</b>: The Holy Scriptures as the authoritative word of God.<br />

                    &bull; <b>The Church</b>: The community of believers and body of Christ.
                    </p>
                    <p>
                    Christian holidays, such as Christmas and Easter, commemorate significant events in the life of Jesus Christ. 
                    These holidays are vital for spiritual reflection, communal worship, and celebrating the central mysteries of the faith. 
                    They provide opportunities for Christians to renew their faith, strengthen their community bonds, and express their beliefs through rituals and traditions.
                    </p>
                    <p>
                    Exchanging gifts on Christian holidays symbolizes love, generosity, and the sharing of blessings. 
                    It reflects the gifts given to Jesus by the Wise Men at his birth and the ultimate gift of salvation offered by Jesus. 
                    Gift-giving fosters a sense of community, gratitude, and joy, enhancing the celebration of these sacred events.
                    </p>
            </div> 
                
                <ArticleListing category={"christian"}></ArticleListing>
            </section>
        </div>
    )
}

export default Christian;