import React from "react";
function Horoscope() {
    return (
      <>
        <div className="py-6 sm:py-12">
          <br /><br />
          <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
            <div className="mx-auto max-w-md px-4">
              <h2><b>Horoscope</b></h2>
              <div className="divide-y divide-gray-300/50">
                <div className="space-y-6 py-8 text-base leading-7 text-black-500 dark:text-white">
                 <h1>WORK IN PROGRESS...</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
  
      </>
  
    );
  }
  export default Horoscope;