import { Outlet } from "react-router-dom";
import TopAppBar from "./TopAppBar";

const Layout = () => {
    return(
        <div>
<TopAppBar></TopAppBar>
            <Outlet />
           
        </div>
    )
}

export default Layout