import React from "react";
import ukraine from "../assets/images/ukraineflag.png";


function TopAppBar(){
    return (
      <nav className="w-full py-4 bg-gradient-to-r from-sky-900  to-sky-500 shadow  navbar-sticky">
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between">
          <nav>
            <ul
              className="flex items-center justify-between font-semibold text-sm text-white uppercase no-underline"
            >
              <li>
                <a className="hover:text-gray-200 hover:underline px-4" href="/">Home</a>
              </li>
              <li>
                <div className="">
                  <div className="dropdown-container">
                    <div className="dropdown">
                      <label className="flex cursor-pointer px-0" tabIndex="0"
                        >Categories
                      </label>
                      <div className="dropdown-menu dropdown-menu-bottom-right  w-42  bg-glass dark:bg-backgroundPrimary">
                      <a href="/state" className="btn  btn-ghost text-sm text-base">State holidays</a><hr />
                        <a href="/christian" className="btn  btn-ghost text-sm text-base">Christian festivals</a><hr />
                        <a href="/muslim" className="btn  btn-ghost text-sm text-base">Muslim festivals</a><hr />
                        <a href="/hindu" className="btn  btn-ghost text-sm text-base">Hindu festivals</a><hr />
                        <a href="/buddhist" className="btn  btn-ghost text-sm text-base">Buddhist festivals</a><hr />
                        <a href="/jewish" className="btn  btn-ghost text-sm text-base">Jewish festivals</a><hr />
                        <a href="/observance" className="btn  btn-ghost text-sm text-base">Observances</a><hr />
                        <a href="/other" className="btn  btn-ghost text-sm text-base">Other</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <a className="hover:text-gray-200 hover:underline px-4" href="/about">About</a>
              </li>
            </ul>
          </nav>
  
          <div className="flex items-center font-semibold text-sm text-white uppercase no-underline text-white pr-2">
          <ul>
            <li>
                <img src={ukraine} width={32} height={32} alt="ukraine will win"></img>
              </li>
            </ul>

            {/*<a href="#" className=""><ion-icon name="logo-facebook"></ion-icon></a>&nbsp;
            <a href="#" className=""><ion-icon name="logo-tumblr"></ion-icon></a>*/}
          </div>
        </div>
      </nav>
  
    );
  }


  export default TopAppBar;